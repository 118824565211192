import React, { Component } from 'react';
import styles from './scss/NoMatch.module.scss';


class NoMatch extends Component {
    render() {
        return (
            <div className={`container ${styles.container}`}>
                <div className={styles.title}>404</div>
                <p className={styles.subtitle}>Page Not Found</p>
            </div>
        );
    }
}

export default NoMatch;