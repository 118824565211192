import React, { Component } from 'react';
import styles from './scss/Title.module.scss';

class Title extends Component {
    
    render() {
        return (
            <div className={styles.titleCont}>
                {this.props.children}
            </div>
        );
    }
}

export default Title;