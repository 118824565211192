const main_state = {
	user: undefined,
	banner_img: require("assets/img/Banner/Header_BG.jpg"),
	games: false,
	top_issue: [],
	category: [],
	alert_open: false,
	alert_title: "",
	alert_content: "",
	tos_open: false,
	notification_open: false
};

const main = (state = main_state, action) => {
	switch (action.type) {
		case "TOGGLE_ALERT":
			return {
				...state,
				alert_open: !state.alert_open
			};
		case "TOGGLE_NOTIFICATION":
			return {
				...state,
				notification_open: action.data
			};
		case "TOGGLE_TOS":
			return {
				...state,
				tos_open: !state.tos_open
			};
		case "PUT_ALERT_CONTENT":
			return {
				...state,
				alert_title: action.title,
				alert_content: action.data
			};
		case "PUT_USER":
			return {
				...state,
				user: action.data
			};
		case "PUT_TOP_ISSUES":
			return {
				...state,
				top_issue: action.data
			};
		case "PUT_CATEGORY":
			return {
				...state,
				category: action.data
			};
		case "PUT_GAMES":
			return {
				...state,
				games: action.data
			};
		case "PUT_BANNER":
			return {
				...state,
				banner_img: action.data
			};
		default:
			return state;
	}
};

export default main;
