import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import styles from './scss/Card.module.scss';

class Card extends Component {
    renderCard = () => {
        const {
            ticketNo,
            date,
            game,
            category,
            sub_category,
            status,
            openModal,
            review,
            last_reply_by,
            is_review,
            put_ticket_number
        } = this.props;

        let is_mobile = window.screen.width < 700 ? 'col-12' : 'col';

        return (
            <div id="Card" className={styles.card}>
                <div className={`row`}>
                    <div className={`col p-0 pb-2 ${styles.card__date}`}>{moment(date).format('DD/MM/YYYY')}</div>
                </div>
                <Link onClick={() => put_ticket_number(ticketNo)} to={`/ticket/list/${ticketNo}`} className={`row mt-2 ${styles.card__row}`}>
                    <div className={`${is_mobile} ${styles.card__col}`}>
                        <div className={`pb-3 ${styles.card__title}`}>Ticket</div>
                        <div className={`${styles.card__content}`}>{ticketNo}</div>
                    </div>
                    <div className={`${is_mobile} ${styles.card__col}`}>
                        <div className={`pb-3 ${styles.card__title}`}>Game</div>
                        <div className={`${styles.card__content}`}>{game}</div>
                    </div>
                    <div className={`${is_mobile} ${styles.card__col}`}>
                        <div className={`pb-3 ${styles.card__title}`}>Kategori</div>
                        <div className={`${styles.card__content}`}>{category}</div>
                    </div>
                    <div className={`${is_mobile} ${styles.card__col}`}>
                        <div className={`pb-3 ${styles.card__title}`}>Sub Kategori</div>
                        <div className={`${styles.card__content}`}>{sub_category}</div>
                    </div>
                    <div className={`${is_mobile} ${styles.card__col}`}>
                        <div className={`pb-3 ${styles.card__title}`}>Status</div>
                        <div className={`${styles.card__content}`}>{status}</div>
                    </div>
                    {
                        (last_reply_by !== "User" && !is_review) &&
                        <div className={styles.card__notif}>NEW</div>
                    }
                </Link>
                { review && <div className={`${styles.card__review__btn}`} onClick={() => {
                    put_ticket_number(ticketNo);
                    openModal();
                }}>Penilaian</div> }
            </div>
        )
    }
    render() {
        return this.renderCard();
    }
}

export default Card;