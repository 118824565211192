import { directus } from "wrapper";

export const get_top_issues = () => {
	return (dispatch) => {
		// otrs.get('support/top_article/?limit=5')
		directus
			.get("items/faq?limit=5&fields=*.*&filter[status]=published&filter[is_top_issue_help]=1&sort=-view_count")
			.then((resp) => {
				dispatch({
					type: "PUT_TOP_ISSUES",
					data: resp
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
