import React, { Component } from 'react';
import styles from './scss/Loader.module.scss';

class Loader extends Component {
    render() {
        return (
            <div id="Loader" className={styles.loader}>
                <img src={require('assets/img/loader.svg')} alt=""/>
            </div>
        );
    }
}

export default Loader;