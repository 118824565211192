import { directus, otrs } from "wrapper"
import { toggleAlert, put_alert_content, map_err } from "base/redux/actions"

const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

export const get_category_article = (slug, type) => {
	return (dispatch) => {
		dispatch(put_data(type, false))
		directus
			.get(
				`items/faq?limit=6&fields=*.*&filter[game.slug]=${slug}&filter[category.type]=${type}&filter[status]=published&sort=-like_count`
			)
			// otrs.get(`support/top_article/?limit=5&game_slug=${slug}&type=${type}`)
			.then((resp) => {
				dispatch(put_data(type, resp))
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const get_article = (slug) => {
	return (dispatch) => {
		directus
			.get(`items/faq?fields=*.*&filter[slug]=${slug}&single=1`)
			.then((resp) => {
				dispatch({
					type: "PUT_ARTICLE",
					data: resp,
				})
				dispatch(check_vote_article(resp.id))
				// dispatch(increment_view(resp.id, resp.view_count))
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const increment_view = (id, count) => {
	return (dispatch) => {
		directus
			.patch(`items/faq/${id}`, {
				view_count: count + 1,
			})
			.then((resp) => {})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const vote_article = (data) => {
	return (dispatch) => {
		otrs
			.post("support/vote_article/", data)
			.then((resp) => {
				dispatch(put_alert_content("SUKSES", "Terima kasih atas tanggapan anda"))
				dispatch(toggleAlert())
				dispatch(check_vote_article(data.get("article_id")))
			})
			.catch((err) => {
				dispatch(map_err(err))
			})
	}
}

export const err = () => {
	return (dispatch) => {
		otrs
			.get("err/")
			.then((resp) => {})
			.catch((err) => {
				console.log(err)
			})
	}
}

export const check_vote_article = (article_id) => {
	return (dispatch) => {
		otrs
			.get(`support/check_vote_article/${article_id}`)
			.then((resp) => {
				dispatch({
					type: "PUT_IS_VOTE",
					data: resp.is_already_vote,
				})
			})
			.catch((err) => {
				dispatch({
					type: "PUT_IS_VOTE",
					data: false,
				})
			})
	}
}
