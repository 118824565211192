import { combineReducers } from "redux";
import main from "base/redux/reducers";
import home from "pages/Home/redux/reducers";
import post from "pages/Post/redux/reducers";
import faq from "pages/FAQ/redux/reducers";
import ticket from "pages/Ticket/redux/reducers";
import list from "pages/List/redux/reducers";
import search from "pages/Search/redux/reducers";

export default combineReducers({
	main, home, post, faq, ticket, list, search
});
