const home_state = {
	top_issue : false
}

const home = (state = home_state, action) => {
    switch (action.type) {
		case "PUT_TOP_ISSUES":
			return {
				...state,
				top_issue: action.data
			}
		default:
			return state;
	}
};

export default home;