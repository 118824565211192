import {directus} from 'wrapper';

export const get_related_article = (slug, query) => {
    return dispatch => {
        dispatch({
            type: 'PUT_RELATED_ARTICLE',
            data: undefined
        })

        let url = `https://supportadm.garena.co.id/_/items/faq?fields=*.*&status=published&sort=-like_count`;
        url += slug === "all" ? '' : `&filter[game.slug]=${slug}`;
        url += (query === "all" || query === 'top issue')  ? '' : `&filter[category.type]=${query}`;

        directus.get(url)
        .then((resp) => {
            dispatch({
                type: 'PUT_RELATED_ARTICLE',
                data: resp
            })
        })
        .catch((err) => {
            console.log(err);
        })
    }
}