import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as actions from 'base/redux/actions';

import './chat.scss';

import TosModal from 'partial/TosModal';
import LoginModal from 'partial/LoginModal';

class Chat extends Component {

    state = {
        toggle_login: false,
        toggle_show: false
    }

    handleModalLogin = () => {
        this.setState({
            toggle_login: !this.state.toggle_login
        })
    }

    openChat = () => {
        window.open("https://chat.cs.garena.co.id/chatserver/chatwindow.aspx?planId=4&siteId=100009000&pageTitle=Garena%20FAQ&pageUrl=http%3A%2F%2F192.168.11.201%2Fcs%2Fpost%2F195&newurl=1&r=2&embInv=0&dirChat=0&guid=&chatGroup=#", '_blank');
    }

    handleChatClick = () => {
        const {user} = this.props.main;
        const {actions} = this.props;
        const is_agree = window.localStorage.is_agree;

        if (!is_agree) {
            actions.toggleTos();
            return;
        }

        if (!user) {
            this.handleModalLogin();
            return;
        }

        this.openChat();
    }

    checkMobile = () => {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ){
            return true;
        } else {
            return false;
        }
    }

    render() {
        const {user, tos_open} = this.props.main;
        const {actions} = this.props

        return (
            <>
                {
                    this.state.toggle_show &&
                    <div id="Chat" onClick={this.handleChatClick}>
                        <img src={this.checkMobile() ? "https://cdngarenanow-a.akamaihd.net/webid/SUPPORT/icon-cs-white.png" : "https://cdngarenanow-a.akamaihd.net/webid/SUPPORT/newbanner.jpg"} alt=""/>
                    </div>
                }

                <TosModal
                    user={user}
                    open={tos_open} 
                    closeModal={actions.toggleTos}
                    openLogin={this.handleModalLogin}
                    openChat={this.openChat}
                />

                <LoginModal
                    open={this.state.toggle_login} 
                    closeModal={this.handleModalLogin}
                />
            </>
        );
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.setState({
        //         toggle_show: true
        //     })
        // }, 30000);
    }
}

function mapStateToProps(state) {
	return { 
		main: state.main
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Chat)