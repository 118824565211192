const faq_state = {
    all_article : [],
    top_article : false
}

const faq = (state = faq_state, action) => {
    switch (action.type) {
        case "PUT_DATA":
            return {
                ...state,
                [action.key]: action.data
            }
		default:
			return state;
	}
};

export default faq;