import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import styles from './scss/TosModal.module.scss';

class TosModal extends Component {

    handleAgree = () => {
        const {
            user,
            openLogin,
            openChat,
            closeModal
        } = this.props;

        window.localStorage.is_agree = true;
        
        closeModal();

        if (user) {
            openChat();
        }
        else
        {
            openLogin();
        }
    }
    
    render() {
        const { open, closeModal } = this.props;
        return (
            <Modal 
                open={open} 
                onClose={closeModal} 
                center
                focusTrapped={false}
                classNames={{
                    modal: styles.modal,
                    closeButton: styles.closeBtn
                }}
            >
                <div className={styles.modalCont}>
                    <div className={styles.content}>
                        <h3>Terms and Conditions of Service</h3>
                        <ol>
                            <li> Pengguna tidak boleh mengirimkan chat/konten agresif, vulgar, dan tidak etis </li>
                            <li> Pengguna tidak boleh meniru identitas orang lain </li>
                            <li> Setiap chat yang masuk akan otomatis direkam oleh system </li>
                            <li> Garena berhak menangguhkan layanan Live Chat kepada pengguna tanpa pemberitahuan sebelumnya </li>
                        </ol>
                        <div className={styles.btnCont}>
                            <div onClick={this.handleAgree} className={styles.btn +" "+ styles.yes}>Saya setuju dengan ketentuan diatas</div>
                            <div onClick={closeModal} className={styles.btn +" "+ styles.no}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default TosModal;