import React, { Component } from "react"
import Modal from "react-responsive-modal"

import styles from "./scss/LoginModal.module.scss"

class LoginModal extends Component {
	handleClick = () => {
		const { redirectTo } = this.props
		if (redirectTo) {
			window.localStorage.setItem("last_page", redirectTo)
		}
	}

	render() {
		const { open, closeModal } = this.props

		return (
			<Modal
				open={open}
				onClose={() => closeModal()}
				center
				classNames={{
					closeButton: "closeButton",
				}}
			>
				<div className={styles.modalCont}>
					<p className={styles.title}>Pilih metode login kamu</p>
					<div>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/garena`}>
							<img src={require("assets/img/Icon/icon_garena.png")} alt="" />
						</a>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/fb`}>
							<img src={require("assets/img/Icon/ico-fb.png")} alt="" />
						</a>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/google`}>
							<img src={require("assets/img/Icon/icon_google.png")} alt="" />
						</a>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/vk`}>
							<img src={require("assets/img/Icon/ico-vk.png")} alt="" />
						</a>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/apple`}>
							<img src={require("assets/img/Icon/icon_apple.png")} alt="" />
						</a>
						<a onClick={this.handleClick} href={`${process.env.REACT_APP_API_ENDPOINT}support/login/twitter`}>
							<img src={require("assets/img/Icon/icon_twitter.png")} alt="" />
						</a>
					</div>
				</div>
			</Modal>
		)
	}
}

export default LoginModal
