import React, { Component } from "react"
import styles from "./scss/Banner.module.scss"

import Search from "partial/Search"

class Banner extends Component {
	handleImage = () => {
		const { data } = this.props
		const pathname = window.location.pathname
		if (data) {
			let filtered = data.filter((dt) => pathname.indexOf(dt.slug) > -1)
			if (filtered.length > 0) {
				return filtered[0].banner
			}
		}

		return "https://cdngarenanow-a.akamaihd.net/gstaticid/Support/Header.jpg"
	}

	render() {
		return (
			<div id="Banner" className={styles.bannerParent} style={{ backgroundImage: `url(${this.handleImage()})` }}>
				<Search />
			</div>
		)
	}
}

export default Banner
