import React, { Component } from 'react';
// import styles from './scss/List.module.scss'
import Title from 'partial/Title'
import FAQLink from 'partial/Link'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import * as actionsBanner from 'base/redux/actions';

class List extends Component {
    
    componentDidMount() {
        const {match, actions, bannerActions} = this.props;
        actions.get_related_article(match.params.games, match.params.type)
        bannerActions.put_banner('change');
    }


    render() {
        const {match, list} = this.props;
        return (
            <div id="List" className="container pt-5 pb-5">
                <Title>{match.params.games} - {match.params.type}</Title>
                <FAQLink data={list.article} />
            </div>
        );
    }
}

function mapStateToProps(state) {
	return { list: state.list }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
        bannerActions: bindActionCreators(actionsBanner, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(List);