const ticket_state = {
	categories: false,
	ticket_list: false,
	ticket_detail: false,
	current_page: 1,
	total_page: 1,
	toggle_modal_review: false,
	toggle_modal_detail: false,
	toggle_modal_submit: false,
	list_file: [],
	formdata: false,
	clear_form_data: false,
	conversation: [],
	ticket_number: false,
	tooltip: "",
	list_feedback: []
}

const ticket = (state = ticket_state, action) => {
    switch (action.type) {
		case "APPEND_CHAT":
            let {data} = action.data;
            
            let obj = {
                created_at : data.created_at,
                content : data.content,
                agent_email : data.agent,
            }

            let conv_copy = [
                ...state.conversation
            ]

            conv_copy.push(obj);

            return {
                ...state,
                conversation: conv_copy
			}
		case "TOGGLE_MODAL_DETAIL":
			return {
				...state,
				toggle_modal_detail: !state.toggle_modal_detail
			}	
		case "TOGGLE_MODAL_REVIEW":
			return {
				...state,
				toggle_modal_review: !state.toggle_modal_review
			}
		case "TOGGLE_MODAL_SUBMIT":
			return {
				...state,
				toggle_modal_submit: !state.toggle_modal_submit
			}
		case "PUT_TICKET_NUMBER": 
			return {
				...state,
				ticket_number: action.data
			}
		case "PUT_CATEGORIES": 
			return {
				...state,
				categories: action.data
			}
		case "PUT_TICKET_LIST":
			return {
				...state,
				ticket_list: action.data,
			}
		case "PUT_TOTAL_PAGE":
			return {
				...state,
				total_page: action.data
			}
		case "PUT_CURRENT_PAGE":
			return {
				...state,
				current_page: action.data
			}
		case "PUT_TICKET_DETAIL":
			return {
				...state,
				ticket_detail: action.data,
				conversation: action.conv
			}
		case "PUT_TOOLTIP":
			return {
				...state,
				tooltip: action.data
			}
		case "PUT_LIST_FILE":
			return{
				...state,
				list_file: action.data
			}
		case "PUT_FORM_DATA":
			return{
				...state,
				formdata: action.data
			}
		case "CLEAR_FORM_DATA":
			return{
				...state,
				clear_form_data: action.data
			}
		case "PUT_LIST_FEEDBACK":
			return{
				...state,
				list_feedback: action.data
			}
		default:
			return state;
	}
};

export default ticket;