import axios from 'axios';

const otrs = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: { [process.env.REACT_APP_TOKEN_HEADER_NAME]: window.localStorage.token },
    withCredentials: true
})

const otrsWrapper = function (options) {
    const onSuccess = function (response) {
        return response.data;
    }

    const onError = function (error) {
        return Promise.reject(error.response || error.message);
    }

    return otrs(options)
        .then(onSuccess)
        .catch(onError)
        .then();
}

function otrsGet(url) {
    return otrsWrapper({
        url: url,
        method: 'GET'
    })
}

function otrsPost(url, data) {
    let options = {
        url: url,
        method: 'POST'
    }

    if (data) {
        options['data'] = data;
    }

    return otrsWrapper(options)
}

const otrsAPI = {
    get: otrsGet, 
    post: otrsPost
}

export default otrsAPI;