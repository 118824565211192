import React, { Component } from "react";
import Modal from "react-responsive-modal";

import styles from "./scss/NotificationModal.module.scss";

class NotificationModal extends Component {
	notificationPermission = () => {
		const { closeModal } = this.props;

		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		} else {
			import("firebase/push-notification").then((myModule) => {
				myModule.askForPermissioToReceiveNotifications();
				closeModal();
			});

			// Notification.requestPermission()
			// 	.then((permission) => {
			// 		if (permission === "granted") {
			// 			closeModal()
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		console.log(err)
			// 	})
			// closeModal();
		}
	};

	rejectNotification = () => {
		const { closeModal } = this.props;

		window.localStorage.setItem("register", true);
		closeModal();
	};

	panduanClick = () => {
		window.location.href = "https://help.garena.co.id/ff/post/cara-mengaktifkan-ticket-notification-android";
	};

	render() {
		const { open, closeModal } = this.props;

		return (
			<Modal
				open={open}
				onClose={closeModal}
				center
				focusTrapped={false}
				classNames={{
					modal: styles.modal,
					closeButton: styles.closeBtn
				}}
			>
				<span className={styles.content}>
					Aktifkan push notifikasi untuk menerima pemberitahuan balasan tiket dari kami
				</span>
				<div className={styles.button}>
					<div className={styles.panduan} onClick={() => this.panduanClick()} />
					<div className={styles.action}>
						<div onClick={() => this.rejectNotification()}></div>
						<div onClick={() => this.notificationPermission()}></div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default NotificationModal;
