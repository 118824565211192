import React, { Component } from "react"
import { Link } from "react-router-dom"
import styles from "./scss/Nav.module.scss"

const navList = [
	{
		text: "Buat Tiket",
		route: "/ticket/create",
		img: "buat-tiket_inactive.png",
		imgActive: "buat-tiket.png",
		type: "create",
	},
	{
		text: "List Tiket",
		route: "/ticket/list",
		img: "list-tiket_inactive.png",
		imgActive: "list-tiket.png",
		type: "list",
	},
	{
		text: "Ulasan Tiket",
		route: "/ticket/review",
		img: "ulasan-tiket_inactive.png",
		imgActive: "ulasan-tiket.png",
		type: "review",
	},
]

class TicketNav extends Component {
	populateNav = () => {
		const { type } = this.props
		return navList.map((data, index) => (
			<Link
				key={index}
				to={data.route}
				className={`${styles.link} text-center ${type === data.type && styles.active}`}
				style={{
					background: `url(${require(`assets/img/Icon/${
						type === data.type ? data.imgActive : data.img
					}`)}) center/auto 100% no-repeat`,
				}}
			/>
		))
	}

	render() {
		return (
			<div id="TicketNav" className={`${styles.Navcontainer}`}>
				<div className={`container ${styles.container}`}>
					<div className={`row ${styles.row}`}>{this.populateNav()}</div>
				</div>
			</div>
		)
	}
}

export default TicketNav
