const search_state = {
	article : undefined
}

const search = (state = search_state, action) => {
    switch (action.type) {
		case "PUT_SEARCH_ARTICLE":
			return {
				...state,
				article: action.data
			}
		default:
			return state;
	}
};

export default search;