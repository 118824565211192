import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { join_room, leave_room } from "socket";
import { stripHTML } from "helper";
import DetailModal from "./Modal";

import styles from "./scss/Detail.module.scss";

import Loader from "partial/Loader";

class Detail extends Component {
	state = {
		content: "",
		files: [],
		filename: [],
		loader: false,
		errors: []
	};

	componentDidMount() {
		const { ticket_number, get_details, updateListFile } = this.props;
		join_room(ticket_number);
		get_details(ticket_number);
		updateListFile([]);

		window.socket.on("agent_otrs_response", this.appendChat);
	}

	componentWillUnmount() {
		const { put_ticket_detail, ticket_number } = this.props;
		put_ticket_detail(false);
		leave_room(ticket_number);
		window.socket.off("agent_otrs_response", this.appendChat);
	}

	appendChat = (msg) => {
		const { append_chat } = this.props;
		append_chat(msg);
	};

	populateChat = (data) => {
		return data.map((dt, idx) => {
			let cont = dt.agent_email ? styles.conversation__other : styles.conversation__me;
			let textCont = dt.agent_email ? styles.conversation__other_textCont : styles.conversation__me_textCont;
			let date = dt.agent_email ? styles.conversation__other_date : styles.conversation__me_date;

			console.log(dt.content_type);

			return (
				<div key={idx} className={cont}>
					{dt.content_type === "json" ? (
						<div className={textCont}>
							<span>{dt.content?.title}</span>
							<p>{dt.content?.desc}</p>
							<div
								className={`${styles.conversation__other_textCont_option} ${
									dt.content?.is_answer && styles.disabled
								}`}
							>
								{dt.content?.choices?.map?.((dt2, idx2) => (
									<button
										key={idx2}
										onClick={() => this.props?.survey(dt.ticket_number, dt.id, dt2.id)}
										className={
											dt.content.is_answer && dt.content.answer !== dt2.id && styles.disabled
										}
									>
										{dt2.id}. {dt2.option}
									</button>
								))}
							</div>
							<span>{dt.content?.footer}</span>
						</div>
					) : dt.agent_email || idx === 0 ? (
						<div className={textCont} dangerouslySetInnerHTML={{ __html: dt.content }}></div>
					) : (
						<div className={textCont}>{dt.content}</div>
					)}
					<div className={date}> {dt.created_at} </div>
				</div>
			);
		});
	};

	handleChange = (e) => {
		if (window.innerWidth < 820) {
			let el = e.currentTarget;
			el.style.height = "";
			el.style.height = Math.min(el.scrollHeight, 100) + "px";
		}

		let val = e.currentTarget.value;

		if (val.length > 1500) return;

		val = stripHTML(val);
		this.setState({
			content: val
		});
	};

	handleReply = async (ticket_number) => {
		const { listFile, handleUpload, showAlert } = this.props;

		const uploadFile = (list, i) => {
			return new Promise((resolve) => {
				if (this.state.files[i]) {
					resolve();
					return;
				}

				handleUpload(
					list,
					// Progress upload
					(event) => {},
					// Callback success
					(url, name) => {
						let temp = [...this.state.files],
							temp_filename = [...this.state.filename];

						temp[i] = url;
						temp_filename[i] = name;

						this.setState({
							files: temp,
							filename: temp_filename
						});

						resolve();
					},
					// Callback error
					(i) => {
						const temp_errors = [...this.state.errors, i];
						this.setState({ errors: temp_errors });

						resolve();
					},
					i
				);
			});
		};

		const uploadFiles = async () => {
			if (listFile.length < 1) return;
			await Promise.all(listFile.map(uploadFile));
		};

		this.setState({ loader: true });

		await uploadFiles();

		if (this.state.errors.length > 0) {
			this.setState({
				errors: [],
				loader: false
			});

			showAlert("Error!", "Silahkan coba lagi");
			return;
		}

		const self = this;
		const { reply, updateListFile } = this.props;
		let formdata = new FormData();

		for (const key in this.state) {
			let content = this.state.content,
				idx = 0;

			if (key === "content") {
				this.state.files.forEach((dt) => {
					content += `\n <a class="d-block" href="${dt}" target="_blank">${this.state.filename[idx]}</a>`;
					idx++;
				});

				formdata.append("content", content);
			}
		}

		reply(
			ticket_number,
			formdata,
			() => {
				self.setState({
					content: "",
					files: [],
					filename: [],
					loader: false
				});
				updateListFile([]);
			},
			() => {
				self.setState({ loader: false });
			}
		);
	};

	handleUpload = (e) => {
		e.preventDefault();

		const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

		const checkFileType = (file) => allowedTypes.includes(file.type);
		const checkFileExistence = (file, list) => list.find((form) => form.get("filename") === file.name);

		const createFormData = (file) => {
			let formData = new FormData();
			formData.append("cvfile", file);
			formData.append("type", "otrs_file");
			formData.append("filename", file.name);

			return formData;
		};

		const { listFile, updateListFile, showAlert } = this.props;

		let file = e.currentTarget.files[0];

		if (this.state.loader) return;
		if (!file?.name) return;

		if (!checkFileType(file)) {
			showAlert("MAAF!", "Hanya file jpg, jpeg, png atau pdf yang diperbolehkan.");
			return;
		}

		const isDuplicate = checkFileExistence(file, listFile);

		if (isDuplicate) {
			showAlert("MAAF!", "File sudah ada");
			return;
		}

		const formData = createFormData(file);

		listFile.push(formData);

		updateListFile(listFile);
	};

	handleRemove = (idx) => {
		if (this.state.loader) return;

		const { listFile, updateListFile } = this.props;

		const temp = [...listFile];

		temp.splice(idx, 1);

		updateListFile(temp);

		if (temp.length === 0) {
			document.getElementById("fileUpload").value = "";
		}
	};

	populateUpload = () => {
		const { listFile } = this.props;

		return listFile.map((file, idx) => {
			let name = file.get("filename");

			const isNameTooLong = name.length > 15;
			name = isNameTooLong ? "..." + name.substring(name.length - 15) : name;

			return (
				<div
					key={idx}
					onClick={() => this.handleRemove(idx)}
					className={`btn btn-small btn-secondary mr-2 ${styles.uploadList}`}
				>
					{name} <span className="badge badge-light ml-1">x</span>
				</div>
			);
		});
	};

	handleClose = () => {
		const { data, closeTicket } = this.props;
		closeTicket(data.ticket_number);
	};

	handleModal = (ticket_number) => {
		const { put_ticket_number, toggle_modal_review } = this.props;
		put_ticket_number(ticket_number);
		toggle_modal_review();
	};

	isEmpty = (obj) => {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	};

	renderTicket = () => {
		const { data, conversation, tooltip, toggle_modal } = this.props;

		if (data) {
			if (this.isEmpty(data)) {
				return <div className={styles.notfound}>Ticket Not Found</div>;
			}

			return (
				<div className="row">
					<div className={`col-md-2 col-12 px-4 pb-1 ${styles.detail}`}>
						<div className={`pb-4 pt-4 mb-4 ${styles.detail__row}`}>
							<p>Date</p>
							<p>{data.created_at}</p>
						</div>
						<div className={`pb-4 mb-4 ${styles.detail__row}`}>
							<p>Ticket</p>
							<p>{data.ticket_number}</p>
						</div>
						<div className={`pb-4 mb-4 ${styles.detail__row}`}>
							<p>Game</p>
							<p>{data.category.name}</p>
						</div>
						<div className={`pb-4 mb-4 ${styles.detail__row}`}>
							<p>Kategori</p>
							<p>{data.sub_category.name}</p>
						</div>
						<div className={`pb-4 mb-4 ${styles.detail__row}`}>
							<p>Sub Kategori</p>
							<p>{data.sub_sub_category.name}</p>
						</div>
						<div className={`pb-4 ${styles.detail__row}`}>
							<p>Status</p>
							<p>{data.status.name}</p>
						</div>
					</div>
					<div className={`col ${styles.chat}`}>
						<div className={`pt-2 ${styles.chat__title}`}>
							<span>Percakapan</span>
							<span data-tip="React-tooltip">?</span>
						</div>
						<div id="conversation" className={`${styles.chat__conversation}`}>
							{this.populateChat(conversation)}
						</div>
						{!data.closed_by && (
							<div className={`${styles.chat__replyCont}`}>
								<textarea
									id="chat_content"
									name="content"
									rows="2"
									className="form-control"
									value={this.state.content}
									placeholder="Tulis pesan balasan disini ..."
									style={{ resize: "none" }}
									onChange={this.handleChange}
								></textarea>
								<div className={`pt-2 d-flex flex-column ${styles.uploadCont}`}>
									<div className="d-flex justify-content-start align-items-center mb-2">
										<input
											type="file"
											className={`form-control-file ${styles.inputUpload}`}
											name="upload"
											id="fileUpload"
											onChange={this.handleUpload}
										/>
										<span className={styles.textInfo}>Upload file tambahan kamu disini</span>
										<span className={styles.textLimit}>{this.state.content.length}/1500</span>
									</div>
									<div className={styles.uploadListCont}>{this.populateUpload()}</div>
								</div>
								<div className="pt-2 d-flex align-items-center justify-content-end">
									<button
										className="btn btn-primary px-3 py-1"
										disabled={this.state.loader}
										onClick={() => this.handleReply(data.ticket_number)}
									>
										Balas
										{this.state.loader && (
											<div
												className="spinner-border spinner-border-sm text-sm ml-1"
												role="status"
											/>
										)}
									</button>
									<button
										className="btn btn-primary ml-2 px-3 py-1 d-none"
										onClick={() => toggle_modal()}
									>
										Akhiri Pertanyaan
									</button>
									{/* <button className="btn btn-primary ml-2 px-3 py-1" onClick={() => toggle_modal()}>Upload</button> */}
								</div>
							</div>
						)}
						{!data.ticket_rating && data.closed_by && (
							<button
								className={`btn btn-danger px-3 py-1 ${styles.review}`}
								onClick={() => this.handleModal(data.ticket_number)}
							>
								Beri Penilaian
							</button>
						)}
					</div>
					<ReactTooltip className={styles.tooltip} place={"right"} type="dark" effect="solid">
						<div dangerouslySetInnerHTML={{ __html: tooltip }} />
					</ReactTooltip>
				</div>
			);
		}

		return <Loader />;
	};

	render() {
		const { toggle_modal, toggle_modal_detail } = this.props;

		return (
			<div id="Detail" className="container pt-5 pb-5">
				{this.renderTicket()}
				<DetailModal open={toggle_modal_detail} closeModal={toggle_modal} closeTicket={this.handleClose} />
			</div>
		);
	}
}

export default Detail;
