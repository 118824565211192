import React, { Component } from "react"

import TicketNav from "./Nav"
import TicketCreate from "./Create"
import TicketList from "./List"
import TicketReview from "./Review"
import TicketDetail from "./Detail"
import ModalSubmit from "./Modal/Submit"
import ModalReview from "./Modal/Review"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsMain from "base/redux/actions"

class Ticket extends Component {
  state = {
    type: null,
  }

  static getDerivedStateFromProps(props) {
    return {
      type: props.match.params.type,
    }
  }

  componentDidMount() {
    const { actions } = this.props
    actions.get_categories()
    actions.get_tooltip()
  }

  renderContent = () => {
    const type = this.state.type
    const num = this.props.match.params.num
    const { categories } = this.props.ticket
    const { user, actions, mainActions, ticket } = this.props

    if (num) {
      let isNum = parseInt(num)
      if (typeof isNum === "number" && isNaN(isNum) === false) {
        return (
          <TicketDetail
            ticket_number={num}
            get_details={actions.ticket_detail}
            data={ticket.ticket_detail}
            conversation={ticket.conversation}
            reply={actions.add_conversation}
            closeTicket={actions.close_ticket}
            append_chat={actions.append_chat}
            tooltip={ticket.tooltip}
            mainActions={mainActions}
            put_ticket_detail={actions.put_ticket_detail}
            put_ticket_number={actions.put_ticket_number}
            toggle_modal_review={actions.toggle_modal_review}
            toggle_modal_detail={ticket.toggle_modal_detail}
            toggle_modal={actions.toggle_modal_detail}
            handleUpload={actions.upload_file}
            listFile={ticket.list_file}
            updateListFile={actions.put_list_file}
            showAlert={actions.show_alert}
            toggleModalSubmit={actions.toggle_modal_submit}
            survey={actions.survey}
          />
        )
      }
      return
    }

    switch (type) {
      case "create":
        return (
          <TicketCreate
            categories={categories}
            user={user}
            listFile={ticket.list_file}
            updateListFile={actions.put_list_file}
            updateFormData={actions.put_form_data}
            clearFormData={ticket.clear_form_data}
            setClearFormData={actions.put_clear_form_data}
            toggleModalSubmit={actions.toggle_modal_submit}
            showAlert={actions.show_alert}
          />
        )
      case "list":
        return (
          <TicketList
            data={ticket.ticket_list}
            current_page={ticket.current_page}
            page={ticket.total_page}
            change_page={actions.put_current_page}
            get_ticket_list={actions.ticket_list}
            put_ticket_number={actions.put_ticket_number}
          />
        )
      case "review":
        return (
          <TicketReview
            data={ticket.ticket_list}
            current_page={ticket.current_page}
            page={ticket.total_page}
            change_page={actions.put_current_page}
            get_ticket_list={actions.ticket_list}
            actions={actions.submit_rating}
            toggle_modal_review={ticket.toggle_modal_review}
            toggle_modal={actions.toggle_modal_review}
            put_ticket_number={actions.put_ticket_number}
          />
        )
      default:
        return
    }
  }

  render() {
    const type = this.state.type
    const { actions, ticket } = this.props
    return (
      <div id="Ticket">
        <TicketNav type={type} />
        <div className="container">
          <div className="row">
            <div className="col">{this.renderContent()}</div>
          </div>
        </div>
        {ticket.toggle_modal_submit && (
          <ModalSubmit
            open={ticket.toggle_modal_submit}
            closeModal={actions.toggle_modal_submit}
            listFile={ticket.list_file}
            formdata={ticket.formdata}
            handleUpload={actions.upload_file}
            handleSubmit={actions.create_ticket}
          />
        )}
        {ticket.toggle_modal_review && (
          <ModalReview
            open={ticket.toggle_modal_review}
            closeModal={actions.toggle_modal_review}
            ticket_number={ticket.ticket_number}
            rate={actions.submit_rating}
            feedback_list={actions.get_feedback_list}
            list_data={ticket.list_feedback}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return { ticket: state.ticket, user: state.main.user.user }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    mainActions: bindActionCreators(actionsMain, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ticket)
