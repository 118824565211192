const post_state = {
	article : undefined,
	is_vote : undefined
}

const post = (state = post_state, action) => {
    switch (action.type) {
		case "PUT_ARTICLE":
			return {
				...state,
				article: action.data
			}
		case "PUT_IS_VOTE":
			return {
				...state,
				is_vote: action.data
			}
		default:
			return state;
	}
};

export default post;