import { directus } from "wrapper";

const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data
});

export const get_article = (slug) => {
	return (dispatch) => {
		directus
			.get(
				`items/faq?limit=5&fields=*.*&filter[game.slug]=${slug}&filter[status]=published&filter[is_top_issue_help]=1&sort=-like_count`
			)
			// otrs.get(`support/top_article/?limit=5&game_slug=${slug}`)
			.then((resp) => {
				dispatch(put_data("top_article", resp));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

export const get_category_article = (slug, type) => {
	return (dispatch) => {
		dispatch(put_data(type, false));
		directus
			.get(
				`items/faq?limit=5&fields=*.*&filter[game.slug]=${slug}&filter[category.type]=${type}&filter[status]=published&sort=-like_count`
			)
			// otrs.get(`support/top_article/?limit=5&game_slug=${slug}&type=${type}`)
			.then((resp) => {
				dispatch(put_data(type, resp));
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
