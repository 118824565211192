import React, { Component } from "react"
import Slider from "react-slick"
import Loader from "partial/Loader"
import { Link } from "react-router-dom"
import style from "./scss/Link.module.scss"

export default class index extends Component {
	populateLink = () => {
		const { data, other, search } = this.props

		if (!data) {
			return <Loader />
		} else if (data.length < 1) {
			return <div className={style.other}>Data tidak ditemukan</div>
		} else {
			return (
				<>
					<Slider
						{...{
							dots: true,
							infinite: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							autoplay: true,
							autoplaySpeed: 10000,
							adaptiveHeight: true
						}}
					>
						{data.map(
							(data, index) =>
								data.game && (
									<Link
										className={style.link}
										key={index}
										to={`/${search ? data.game.slug : data.game.slug}/post/${search ? data.slug : data.slug}`}
									>
										<div>
											<img src={data.banner_img || "https://via.placeholder.com/1280x720.jpg?text=x"} alt="" />
											<span>{search ? data.title : data.title}</span>
										</div>
									</Link>
								)
						)}
					</Slider>

					{other && (
						<Link className={style.other} key={"other"} to={other}>
							Lihat selengkapnya...
						</Link>
					)}
				</>
			)
		}
	}

	render() {
		return <>{this.populateLink()}</>
	}
}
