import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import styles from './scss/Modal.module.scss';

class DetailModal extends Component {
    render() {
        const {open, closeModal, closeTicket} = this.props
        return (
            <Modal 
                open={open} 
                onClose={() => closeModal()} 
                center
                classNames={{
                    modal: styles.modal,
                    closeButton: styles.closeBtn
                }}
            >
                <div className={styles.title}>Perhatian</div>
                <p>Apa kamu yakin ingin menutup tiket ini?</p>
                <div className={styles.btnCont}>
                    <div className={`btn btn-sm btn-secondary w-25`} onClick={() => closeTicket()}>Ya</div>
                    <div className={`btn btn-sm btn-danger w-25`} onClick={() => closeModal()}>Tidak</div>
                </div>
            </Modal>
        );
    }
}

export default DetailModal;