import React, { Component } from 'react';
import styles from './scss/Footer.module.scss';

class Footer extends Component {
    render() {
        return (
            <div id="Footer" className={styles.footer}>
                Copyright © Garena Online. Trademarks belong to their respective owners. All right reserved.
            </div>
        );
    }
}

export default Footer;