import React, { Component } from 'react';

import style from './scss/Search.module.scss'

class Search extends Component {
    handleSearch = (e) => {
        let val = e.currentTarget.value;
        if (e.key === 'Enter') {
            window.location.href = process.env.REACT_APP_BASE_URL + `search/${val}`
        }
    }

    render() {
        return (
            <div id="Search" className={style.searchCont}>
                <div className={style.magnifier}><img src={require('assets/img/Icon/Icon_Search.png')} alt=""/></div>
                <input type="text" placeholder="Temukan permasalahan anda ..." onKeyUp={this.handleSearch} />
            </div>
        );
    }
}

export default Search;