import React, { Component } from "react"
import Modal from "react-responsive-modal"

import styles from "./scss/AlertModal.module.scss"

class AlertModal extends Component {
	render() {
		const { open, closeModal, title, content } = this.props
		return (
			<Modal
				open={open}
				onClose={() => closeModal()}
				center
				focusTrapped={false}
				classNames={{
					modal: styles.modal,
					closeButton: styles.closeBtn,
				}}
			>
				<div className={styles.modalCont}>
					<div className={styles.title}>{title}</div>
					<div
						className={styles.content}
						dangerouslySetInnerHTML={{ __html: content ? content : "Silahkan Refresh Browser Anda" }}
					></div>
				</div>
			</Modal>
		)
	}
}

export default AlertModal
