import React, { Component } from "react"
import { Link } from "react-router-dom"
import Title from "partial/Title"
import Loader from "partial/Loader"
import LoginModal from "partial/LoginModal"

import styles from "./scss/Post.module.scss"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "./redux/actions"
import * as actionsBanner from "base/redux/actions"

class Post extends Component {
	state = {
		vote: null,
		review: "",
		toggle_login: false,
	}

	componentDidMount() {
		this.getArticle()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.post !== this.props.post) {
			const { actions, post } = this.props
			actions.get_category_article(post.article?.game.slug, post.article?.category.type)
		}
		if (prevProps.location !== this.props.location) {
			this.getArticle()
		}
	}

	getArticle = () => {
		const { actions, match, bannerActions } = this.props
		actions.get_article(match.params.slug)
		bannerActions.put_banner("change")
	}

	handleChange = (e) => {
		this.setState({
			review: e.currentTarget.value,
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const { post, actions } = this.props
		const { article } = post
		let formdata = new FormData()

		for (const key in this.state) {
			formdata.append(key, this.state[key])
		}

		formdata.append("article_id", article.id)
		// formdata.append('game_slug', article.game.slug);
		// formdata.append('faq_type', article.category.type);

		// actions.err();
		actions.vote_article(formdata)
	}

	handleMoreHelp = (e) => {
		e.preventDefault()
		const { user } = this.props

		if (!user) {
			this.handleModalLogin()
		} else {
			window.location.href = process.env.REACT_APP_BASE_URL + "ticket/create"
		}
	}

	handleModalLogin = () => {
		this.setState({
			toggle_login: !this.state.toggle_login,
		})
	}

	populateRate = () => {
		const { user, post } = this.props
		const { is_vote } = post

		if (!user) {
			return
		}

		return (
			<form onSubmit={this.handleSubmit}>
				<div className={styles.formCont}>
					{!is_vote ? (
						<div className={styles.rateCont}>
							<p>Apakah artikel ini membantu?</p>
							<div className={styles.btnCont}>
								<button
									type="submit"
									className={`${styles.btn} ${this.state.vote === 1 && styles.active}`}
									onClick={() =>
										this.setState({
											vote: 1,
										})
									}
								>
									Ya
								</button>
								<div
									className={`${styles.btn} ${this.state.vote === 0 && styles.active}`}
									onClick={() =>
										this.setState({
											vote: 0,
										})
									}
								>
									Tidak
								</div>
							</div>
							<div className={styles.reviewCont + " " + (this.state.vote === 0 && styles.active)}>
								<p>Bantu kami meningkatkan kembali jawabannya</p>
								<textarea
									name=""
									id=""
									rows="5"
									placeholder="Tulis ulasan kamu disini..."
									onChange={this.handleChange}
								></textarea>
								<button type="submit" className={styles.btn}>
									Kirim
								</button>
							</div>
						</div>
					) : (
						<p>Anda telah memberi penilaian terhadap artikel ini</p>
					)}
				</div>
			</form>
		)
	}

	populateArticle = () => {
		const { article } = this.props.post

		switch (article) {
			case undefined:
				return <Loader />
			case false:
				return (
					<>
						<Title>NOT FOUND</Title>
						<div className={styles.postCont} dangerouslySetInnerHTML={{ __html: "ARTICLE NOT FOUND" }}></div>
					</>
				)
			default:
				return (
					<>
						<Title>{article.title}</Title>
						<div className={styles.postCont} dangerouslySetInnerHTML={{ __html: article.article }}></div>
					</>
				)
		}
	}

	populateLink = () => {
		const { faq, post } = this.props
		const data = faq[post.article?.category.type]?.filter?.((e) => e.id !== post.article?.id)

		if (data) {
			return data?.map?.((dt, idx) => (
				<Link key={idx} to={`/${dt.game.slug}/post/${dt.slug}`}>
					<img src={dt.banner_img || "https://via.placeholder.com/1280x720.jpg?text=x"} alt="" />
					<span>{dt.title}</span>
				</Link>
			))
		} else {
			return <Loader />
		}
	}

	render() {
		const { match, post } = this.props

		return (
			<div className="container">
				<div className="row">
					<div id="Post" className={`pt-5 pb-5 ${styles.post} col`}>
						{this.populateArticle()}
						<div className={styles.moreHelp}>
							<span>Butuh bantuan lebih lanjut? </span>
							<span onClick={this.handleMoreHelp} href="">
								Klik disini
							</span>
						</div>
						{this.populateRate()}
						<LoginModal
							open={this.state.toggle_login}
							closeModal={this.handleModalLogin}
							redirectTo={process.env.REACT_APP_BASE_URL + "ticket/create"}
						/>
					</div>
					<div className={`col-sm-3 ${styles.article} pr-sm-0 py-5`}>
						<span className={styles.title}>Artikel terkait</span>
						{this.populateLink()}
						<Link className={styles.other} to={`/${match.params.games}/list/${post.article?.category.type}`}>
							Lihat selengkapnya...
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		post: state.post,
		user: state.main.user,
		faq: state.faq,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		bannerActions: bindActionCreators(actionsBanner, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
