import {directus} from 'wrapper';

export const get_search_article = (query) => {
    return dispatch => {
        dispatch({
            type: 'PUT_SEARCH_ARTICLE',
            data: undefined
        })

        let url = `https://supportadm.garena.co.id/_/items/faq?fields=*.*&status=published&q=${query}`;

        directus.get(url)
        .then((resp) => {
            dispatch({
                type: 'PUT_SEARCH_ARTICLE',
                data: resp
            })
        })
        .catch((err) => {
            console.log(err);
        })
    }
}