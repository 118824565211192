import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Loader from 'partial/Loader';

import style from './scss/Link.module.scss'

class FAQLink extends Component {
    
    populateLink = () => {
        const {data, other, search} = this.props;

        if(!data) {
            return <Loader />
        }

        let list = [];
        if (data.length < 1) {
            return <div className={style.other}>Data tidak ditemukan</div>;
        }

        data.map((data,index) => {
            if (data.game) {
                list.push(
                    <Link className={style.link} key={index} to={`/${search ? data.game.slug : data.game.slug}/post/${search ? data.slug : data.slug}`}>
                        <span>{search ? data.title : data.title}</span>
                        <span>&#9660;</span>
                    </Link>
                )
            }

            return list;
        })

        if (other) {
            list.push(
                <Link className={style.other} key={'other'} to={other}>Lihat selengkapnya...</Link>
            )
        }


        return list;
    }

    render() {
        return (
            <>
                {this.populateLink()}
            </>
        )
    }
}

export default FAQLink;