import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

import styles from './scss/List.module.scss';

import Card from 'pages/Ticket/Card';
import Loader from 'partial/Loader';

class ListTicket extends Component {

    handlePageClick = (data) => {
        const {change_page, get_ticket_list} = this.props;
        change_page(data.selected + 1);
        get_ticket_list(data.selected + 1)
    }

    componentDidMount() {
        const {get_ticket_list} = this.props
        get_ticket_list(1)
    }
    
    populateTicket = () => {
        const {data, put_ticket_number} = this.props;

        if (!data) {
            return <Loader />
        }

        if (data.length < 1) {
            return <div className={styles.noData}>Tidak ada data</div>
        }

        return data.map(dt => 
            <Card 
                key={dt.id} 
                ticketNo={dt.ticket_number} 
                date={dt.created_at} 
                game={dt.category.name}
                category={dt.sub_category.name}
                sub_category={dt.sub_sub_category.name}
                status={dt.status}
                last_reply_by={dt.last_reply_by}
                put_ticket_number={put_ticket_number}
            />
        )
    }

    render() {
        const {data, page, current_page} = this.props;
        
        return (
            <div id="ListTicket" className={`container pt-4 pb-5 ${styles.listTicket}`}>
                {this.populateTicket()}
                {
                    (data && data.length > 0) && 
                    <ReactPaginate
                        pageCount={page}
                        forcePage={current_page - 1}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={5}
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        onPageChange={this.handlePageClick}
                    />
                }
            </div>
        );
    }
}

export default ListTicket;